import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { AxiosError } from 'axios'

Vue.config.productionTip = false
Vue.use(PiniaVuePlugin)

const pinia = createPinia()

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err)
  if (err instanceof AxiosError &&
      err.response?.status == 401 &&
      err.response?.data.message == "Unauthorized") {
    vm.$router.push('/login')
  }
}

new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')