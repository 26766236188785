






































import { Component, Vue } from 'vue-property-decorator'
import { useStore } from './store';

@Component({})
export default class App extends Vue {
  get store() {
    return useStore()
  }
}
