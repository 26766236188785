import { defineStore } from "pinia"
import { User } from "./client"

type Notification = {
  message: string,
  href?: string,
  id: number
}

export const useStore = defineStore('main', {
  state: () => ({
    currentNotification: null as Notification | null,
    notificationId: 0,
    currentUser: null as User | null
  }),
  actions: {
    pushNotification(message: string, href?: string) {
      const notificationId = this.notificationId++
      this.currentNotification = {
        message,
        href,
        id: notificationId
      }
      console.log(this.currentNotification)

      setTimeout(() => {
        if (this.currentNotification && this.currentNotification.id == notificationId) {
          this.currentNotification = null
        }
      }, 7000)
    }
  }
})