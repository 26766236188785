import { REQUIRED_TOS_KEY, USER_DATA_KEY } from '@/constants'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/confirm-registration',
    name: 'ConfirmRegistration',
    component: () => import(/* webpackChunkName: "confirm-registration" */ '../views/ConfirmRegistration.vue')
  },
  {
    path: '/register-done',
    name: 'RegisterDone',
    component: () => import(/* webpackChunkName: "register-done" */ '../views/RegisterDone.vue')
  },
  {
    path: '/register-done-trader',
    name: 'RegisterDoneTrader',
    component: () => import(/* webpackChunkName: "register-done-trader" */ '../views/RegisterDoneTrader.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
  },
  {
    path: '/app',
    name: 'Console',
    component: () => import(/* webpackChunkName: "console" */ '../views/Console.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
      },
      {
        path: 'trade',
        name: 'Trade',
        component: () => import(/* webpackChunkName: "trade" */ '../views/Trade.vue')
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: () => import(/* webpackChunkName: "transactions" */ '../views/Transactions.vue')
      }
    ]
  },
  {
    path: '/trader/:userId',
    name: 'Trader',
    component: () => import(/* webpackChunkName: "trader" */ '../views/Trader.vue')
  },
  {
    path: '/tos/:tosVersion',
    name: 'ToS',
    component: () => import(/* webpackChunkName: "tos" */ '../views/ToS.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/app') && !localStorage.getItem(USER_DATA_KEY)) {
    next({ path: '/login' })
    return
  }

  const requiredTosVersion = localStorage.getItem(REQUIRED_TOS_KEY)
  if (to.path.startsWith('/app') && requiredTosVersion !== null) {
    next({ path: `/tos/${requiredTosVersion}`})
    return
  }

  next()
})

export default router
